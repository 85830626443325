import { GlobalAppConfig } from './../models/global-app-config';
import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.abstract.service';
import { Firestore, FirestoreDataConverter, query, where } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class GlobalAppConfigurationService extends FirestoreService<GlobalAppConfig> {


  protected basePath: string = 'global-app-config';


  constructor(
    fireStore: Firestore
  ) {
    super(fireStore);
  }

  async getLinkingKey() {
    return false;
    // Define a custom query function using Firestore query chaining methods
    const customQueryFn = (collectionRef: any) => {
      return query(collectionRef, where("name", "==", "linkingKey"));
    };

    const querySnapshot = await this.getCollection$(customQueryFn);
    const doc = querySnapshot.docs[0];
    return doc.data();
  }

  async getFunctionToken() {
    // Define a custom query function using Firestore query chaining methods
    const customQueryFn = (collectionRef: any) => {
      return query(collectionRef, where("name", "==", "functionApiToken"));
    };

    const querySnapshot = await this.getCollection$(customQueryFn);
    const doc = querySnapshot.docs[0];
    return doc.data();
  }

  async getMsspFunctionToken() {


    // Define a custom query function using Firestore query chaining methods
    const customQueryFn = (collectionRef: any) => {
      return query(collectionRef, where("name", "==", "MsspFunctionApiToken"));
    };

    const querySnapshot = await this.getCollection$(customQueryFn);
    const doc = querySnapshot.docs[0];
    return doc.data();
  }

  async getOpenAIToken() {


    // Define a custom query function using Firestore query chaining methods
    const customQueryFn = (collectionRef: any) => {
      return query(collectionRef, where("name", "==", "openai_key"));
    };

    const querySnapshot = await this.getCollection$(customQueryFn);
    const doc = querySnapshot.docs[0];
    return doc.data();
  }


  override getConverter(): FirestoreDataConverter<any> {
    return {
      // Convert User object to Firestore-compatible format
      toFirestore: (globalConfig: any) => {
        let globalAppConfigData: GlobalAppConfig = {
          uid: globalConfig.uid,
          name: globalConfig.name,
          description: globalConfig.description,
          value: globalConfig.value,
          createdAt: globalConfig.createdAt ? globalConfig.createdAt : new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
        globalAppConfigData = this.removeEmpty(globalAppConfigData); // Remove any empty/null values
        return globalAppConfigData;
      },
      // Convert Firestore snapshot to User object
      fromFirestore: (snapshot: any, options: any) => {
        const data = snapshot.data(options);
        return new GlobalAppConfig(data);
      },
    };
  }

}
