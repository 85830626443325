export class TenableChildAccount {
    uuid!: string
    container_name!: string
    custom_name?: string;
    sso_username!: string
    region!: string
    site_id!: string
    licensed_assets!: number
    licensed_assets_limit!: number
    license_utilization!: number
    license_expiration_date!: number
    licensed_apps!: string[]
    logo_uuid!: string
    domains!: string[]

    constructor(data: object) {
      Object.assign(this, data);
    }
}
