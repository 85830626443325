// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyBGBgXsgJnZKvm0FXAiQmfrcsLWb4nqhpA",
    authDomain: "scan-ninja.firebaseapp.com",
    databaseURL: "https://scan-ninja-default-rtdb.firebaseio.com",
    projectId: "scan-ninja",
    storageBucket: "scan-ninja.appspot.com",
    messagingSenderId: "695443543532",
    appId: "1:695443543532:web:89a753e3cbab4600f75cc9",
    measurementId: "G-SGE1JRVVCJ"
  },
  results_url: "",
  production: false,
  debug: true,
  email: "no_reply@labworkz.com",
  emailServiceUrl: "https://funlabworkzemailfun.azurewebsites.net/api/send_email?code=WoQinGKe0YKRna9JH2lV__DIWYDw9dHa4hTMmbRET2SEAzFunOS2sw==",
  base_function_url: 'https://func-scan-ninja-py-dev.azurewebsites.net',
  mssp_function_url: 'https://func-scanninja-dev.azurewebsites.net',
  stripe_api_key: '',
  app_api_url: '',
  app_url: 'https://dev.dashboard.scanninja.app/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
