import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { SeoService } from '@core/services/seo.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from '@dashboard/shared/alert-modal/alert-modal.component';
import { Company } from '../models/company';
import { TenableChildAccount } from '../tenable/models/tenable-child-account';


@Injectable({
  providedIn: 'root',
})
export class ThemeService {

  private sidebarColor: string = 'blue';
  private spinnerShow = new BehaviorSubject<boolean>(false);
  public pageTitleSub: BehaviorSubject<string> = new BehaviorSubject('');

  public sideBarvisible: boolean = false;
  public sideBarVisable$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private seoService: SeoService,
    private modalService: NgbModal
    ) {
      this.checkForColors();
    }

  setSeoData(title: string, description?: string, imageUrl?: string) {
    this.seoService.generateTags(title, description, imageUrl);
  }

  getPageTitle(): Observable<string> {
    return this.pageTitleSub.asObservable();
  }

  setPageTitle(title: string) {
    this.pageTitleSub.next(title);
  }

  isDarkMode(): boolean {
    const currentColor = sessionStorage.getItem('dashboardColor');
    return currentColor == 'black-content' ? true : false;
  }

  changeSidebarColor(color: string) {
    const sidebar = document.getElementsByClassName('sidebar')[0];
    const mainPanel = document.getElementsByClassName('main-panel')[0];
    const body = document.getElementsByTagName('body')[0];
    this.sidebarColor = color;

    if (sidebar != undefined) {
      sidebar.setAttribute('data', color);
    }
    if (mainPanel != undefined) {
      mainPanel.setAttribute('data', color);
    }

    // Add custom CSS to change colors of items:
    // .ng2-tag-input.regular-theme tag
    if (body != undefined) {
      body.setAttribute('data', color);
    }

    sessionStorage.setItem('sideBarColor', this.sidebarColor);
  }
  changeDashboardColor(color: string) {
    const body = document.getElementsByTagName('body')[0];
    if (body && color === 'white-content') {
      body.classList.add(color);
    } else if (body.classList.contains('white-content')) {
      body.classList.remove('white-content');
    }

    sessionStorage.setItem('dashboardColor', color);
  }

  getSideBarColor(): string {
    const sidebarColor = sessionStorage.getItem('sideBarColor');
    if (sidebarColor) {
      this.sidebarColor = sidebarColor;
    }

    return this.sidebarColor;
  }

  checkForColors() {
    const localSidebarColor = sessionStorage.getItem('sideBarColor');
    if (localSidebarColor) {
      this.changeSidebarColor(localSidebarColor);
    }

    const localDashboardColor = sessionStorage.getItem('dashboardColor');
    if (localDashboardColor) {
      this.changeDashboardColor(localDashboardColor);
    }

    const darkMode = sessionStorage.getItem('darkMode');
    if (darkMode) {
      document.body.setAttribute('data-bs-theme', darkMode);
    }
  }

  showSpinner() {
    this.spinnerShow.next(true);
  }

  hideSpinner() {
    this.spinnerShow.next(false);
  }
  getShowSpinner(): Observable<boolean> {
    return this.spinnerShow.asObservable();
  }

  toggleSidebarVisibility() {
    this.sideBarvisible = !this.sideBarvisible;
    this.sideBarVisable$.next(this.sideBarvisible);
  }

  getSidebarIsVisable(): Observable<boolean> {
    return this.sideBarVisable$.asObservable();
  }

// This is for when the register section is using select plan page
  public isSelectPlanPage: boolean = false;
  public isSelectPlanPage$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  setSelectPlanPageLayout(isSet = false) {
    this.isSelectPlanPage = isSet;
    this.isSelectPlanPage$.next(this.isSelectPlanPage);
  }

  getSelectPlanPage(): Observable<boolean> {
    return this.isSelectPlanPage$.asObservable();
  }


  private errorMessageSource = new BehaviorSubject<string>('');
  currentErrorMessage = this.errorMessageSource.asObservable();
  triggerError(message: string) {
    this.errorMessageSource.next(message);
    this.triggerModal(message);
  }

  triggerModal(message: string) {
    const modalRef = this.modalService.open(AlertModalComponent);
    modalRef.componentInstance.message = message;
  }

  public sideBarCompanyModel?: Company;
  public sideBarCompanyModel$: BehaviorSubject<Company> = new BehaviorSubject(new Company({}));

  setSidebarCompanyModel(companyModel: Company) {
    this.sideBarCompanyModel = companyModel;
    this.sideBarCompanyModel$.next(this.sideBarCompanyModel);
  }

  getSidebarCompanyModel(): Observable<Company> {
    return this.sideBarCompanyModel$.asObservable();
  }


  public accountModel?: TenableChildAccount;
  public accountModel$: BehaviorSubject<TenableChildAccount> = new BehaviorSubject(new TenableChildAccount({}));

  setAccountModel(companyModel: TenableChildAccount) {
    this.accountModel = companyModel;
    this.accountModel$.next(this.accountModel);
  }

  getAccountModel(): Observable<TenableChildAccount> {
    return this.accountModel$.asObservable();
  }

}
