import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GlobalAppConfigurationService } from '@core/services/global-app-configuration.service';
import { BehaviorSubject, Observable, catchError, filter, of, switchMap, tap, throwError } from 'rxjs';
import { TenableScanner } from '@core/tenable/models/tenable-scanner';
import { CompanyService } from '@core/services/company.service';
import { ErrorHandlerService } from 'src/app/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class TenableFunctionsService {
  private defaultCacheDuration = 7 * 24 * 60 * 60 * 1000; // Default cache duration: 7 days in milliseconds

  public companySlug?: string;
  private functionUrl = environment.base_function_url + '/api/tenable';
  private companyHeaderObject?: { company_slug: string; };
  public apiTokenSource = new BehaviorSubject<string | null>(null);
  public apiToken$ = this.apiTokenSource.asObservable();

  constructor(
    private http: HttpClient,
    private globalConfigService: GlobalAppConfigurationService,
    private companyService: CompanyService,
    private errorService: ErrorHandlerService
  ) {

  }

  /**
   * Sets the token for the Tenable Functions Service.
   * @returns Promise<void>
   */
  setToken(): Promise<void> {
    try {
      this.setCompanySlug();
      return this.globalConfigService.getFunctionToken().then((tokenData: any) => {
        if (tokenData) {
          this.apiTokenSource.next(tokenData.value);
        }
      });
    } catch (error) {
      console.error("Error in Set Token Tenable Functions Service ", error);
      this.errorService.handleError(error);
      return Promise.resolve();
    }
  }

  /**
   * Sets the company slug for the current user.
   */
  setCompanySlug() {
    const companySlug = sessionStorage.getItem('companySlug');
    if (companySlug) {
      this.companySlug = companySlug;
      this.companyHeaderObject = { "company_slug": this.companySlug }
    } else {

      this.companyService.getCompanySlug().subscribe((slug: string | null) => {
        if (slug) {
          sessionStorage.setItem('companySlug', slug);
          this.companySlug = slug;
          this.companyHeaderObject = { "company_slug": this.companySlug }
        }
      });
    }
  }

  /**
 * Handles HTTP errors by extracting the error message and returning it as an Observable error.
 * @param error The error object caught by catchError.
 * @returns An Observable that emits an error with a custom or generic error message.
 */
  private handleError(error: any): Observable<never> {
    const errorMessage = error.error?.message || 'An unexpected error occurred';
    this.errorService.handleError(error);
    return throwError(() => new Error(errorMessage));
  }

  /**
   * Retrieving from Cache: Before making an HTTP call, we'll first check if a valid cached version of the data exists. If it does, and it's not older than 7 days, we'll use that instead of making a new HTTP call.
   * @param key
   * @returns
   */
  private getCachedResponse(key: string): any {
    const cached = sessionStorage.getItem(key);
    if (!cached) return null;

    const { data, timestamp, duration } = JSON.parse(cached);
    const isExpired = Date.now() - timestamp > duration;
    return isExpired ? null : data;
  }

  /**
   * Caching Responses: When the HTTP call is made and a response is received, we will store it in the browser's local storage along with a timestamp. This will allow us to check if the cached data is still valid (i.e., not older than 7 days).
   * @param key
   * @param data
   * @param duration Default cache duration: 7 days in milliseconds
   */
  private setCache(key: string, data: any, duration: number = this.defaultCacheDuration): void {
    const cacheEntry = {
      timestamp: Date.now(),
      data: data,
      duration: duration
    };
    sessionStorage.setItem(key, JSON.stringify(cacheEntry));
  }


  /**
   * Clear Cache Method: Provide a method to clear the cache. This can be done by removing specific entries from local storage or clearing it entirely.
   * @param key
   */
  clearCache(key: string): void {
    sessionStorage.removeItem(key);
  }

  // /api/tenable/create-scan
  sendScanRequest(payload: any) {
    this.setToken();
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return []; // Return an empty observable or handle as needed
        }
        if (!this.companyHeaderObject) {
          throw new Error('Company Header Not Set');
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        const finalPayload = { ...payload, ...this.companyHeaderObject };

        return this.http.post(this.functionUrl + '/create-scan', finalPayload, httpOptions).pipe(
          catchError(this.handleError)
        );
      })
    );
  }


  listScanTemplates(): Observable<any> {
    const cacheKey = 'listScanTemplates';
    const cachedResponse = this.getCachedResponse(cacheKey);
    if (cachedResponse) return of(cachedResponse);

    this.setToken();
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return of([]); // Return an empty observable or handle as needed
        }
        if (!this.companyHeaderObject) {
          throw new Error('Company Header Not Set');
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        return this.http.post(this.functionUrl + '/list-scan-templates', this.companyHeaderObject, httpOptions).pipe(
          tap(response => this.setCache(cacheKey, response)),
          catchError(this.handleError)
        );
      })
    );
  }


  // /api/tenable/scans/{scan_id}
  getScansById(scan_id: string) {

    const cacheKey = 'getScansById';
    const cachedResponse = this.getCachedResponse(cacheKey);
    if (cachedResponse) return of(cachedResponse);

    this.setToken();
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return []; // Return an empty observable or handle as needed
        }
        if (!this.companyHeaderObject) {
          throw new Error('Company Header Not Set');
        }

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        return this.http.post(this.functionUrl + `/scans/${scan_id}`, this.companyHeaderObject, httpOptions).pipe(
          tap(response => this.setCache(cacheKey, response, 30000)),
          catchError(this.handleError)
        );
      })
    );
  }


  /**
 * Retrieves a list of scanners from the Tenable API.
 * This method makes a POST request to the Tenable API endpoint to fetch scanners.
 * If the request fails due to an error, it catches the error and returns it to the caller.
 *
 * @returns An Observable that emits the list of scanners on success.
 *          If the API call fails, it returns an Observable that emits an error object.
 */
  listScanners(): Observable<TenableScanner[]> {

    const cacheKey = 'listScanners-scanninja';
    const cachedResponse = this.getCachedResponse(cacheKey);
    if (cachedResponse) return of(cachedResponse);

    this.setToken();
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return []; // Return an empty observable or handle as needed
        }

        if (!this.companyHeaderObject) {
          throw new Error('Company Header Not Set');
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };
        return this.http.post<TenableScanner[]>(this.functionUrl + '/scanners', this.companyHeaderObject, httpOptions).pipe(
          tap(response => this.setCache(cacheKey, response, 30)),
          catchError(this.handleError)
        );
      })
    );

  }

  // /api/tenable/scannerKey
  /**
 * Retrieves a Linking key for scanners from the Tenable API.
 * This method makes a POST request to the Tenable API endpoint to fetch scanner linking key.
 * If the request fails due to an error, it catches the error and returns it to the caller.
 *
 * @returns An Observable that emits the Scanner Linking Key on success.
 *          If the API call fails, it returns an Observable that emits an error object.
 */
  getScannerKey() {
    const cacheKey = 'scannerLinkingKey';
    const cachedResponse = this.getCachedResponse(cacheKey);
    if (cachedResponse) return of(cachedResponse);

    this.setToken();
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return []; // Return an empty observable or handle as needed
        }
        if (!this.companyHeaderObject) {
          throw new Error('Company Header Not Set');
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        return this.http.post(this.functionUrl + '/scannerkey', this.companyHeaderObject, httpOptions).pipe(
          tap(response => this.setCache(cacheKey, response)),
          catchError(this.handleError)
        );
      })
    );

  }

  // tenable/get-scan-template-details
  getScanTemplateDetails(template_uid_input: string): Observable<any> {
    // console.log("Get Scan Template Details");

    const cacheKey = 'listScanTemplates-' + template_uid_input;
    const cachedResponse = this.getCachedResponse(cacheKey);
    if (cachedResponse) return of(cachedResponse);

    this.setToken();
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return of([]); // Return an empty observable or handle as needed
        }
        if (!this.companyHeaderObject) {
          throw new Error('Company Header Not Set');
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };
        // bbd4f805-3966-d464-b2d1-0079eb89d69708c3a05ec2812bcf
        const payload = {
          template_uid: template_uid_input
        };
        const finalPayload = { ...payload, ...this.companyHeaderObject };
        return this.http.post(this.functionUrl + `/get-scan-template-details/${template_uid_input}`, finalPayload, httpOptions).pipe(
          tap(response => this.setCache(cacheKey, response)),
          catchError(this.handleError)
        );
      })
    );
  }


  // /api/tenable/create-folder
  // /api/tenable/delete-folders
  // /api/tenable/list-folders
  // /api/tenable/rename-folder

  // /api/tenable/asset-details
  // /api/tenable/host-details

  // /api/tenable/latest-scan-status
  // /api/tenable/latest-scan-progress
  // /api/tenable/update-scan-status

  // /api/tenable/list-assets
  // /api/tenable/list-scan
  getAssetList() {
    // console.log("Get Asset Lists");

    const cacheKey = 'account-assets'
    const cachedResponse = this.getCachedResponse(cacheKey);
    if (cachedResponse) return of(cachedResponse);

    this.setToken();
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return of([]); // Return an empty observable or handle as needed
        }
        if (!this.companyHeaderObject) {
          throw new Error('Company Header Not Set');
        }

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        const finalPayload = { ...{}, ...this.companyHeaderObject };
        return this.http.post(this.functionUrl + `/list-assets`, finalPayload, httpOptions).pipe(
          tap(response => this.setCache(cacheKey, response)),
          catchError(this.handleError)
        );
      })
    );
  }


  getAssetDetails(uid: string) {

    this.setToken();
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return of([]); // Return an empty observable or handle as needed
        }
        if (!this.companyHeaderObject) {
          throw new Error('Company Header Not Set');
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        const finalPayload = { ...{ asset_uuid: uid }, ...this.companyHeaderObject };
        return this.http.post(this.functionUrl + `/asset-details`, finalPayload, httpOptions).pipe(
          catchError(this.handleError)
        );
      })
    );
  }

}
