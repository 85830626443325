import { Injectable, NgZone } from '@angular/core';
import { Firestore, where, FirestoreDataConverter, collection, QuerySnapshot, QueryDocumentSnapshot, query, onSnapshot } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { User } from '../models/user';
import { FirestoreService } from './firestore.abstract.service';
import { Company } from '@core/models/company';

@Injectable({
  providedIn: 'root'
})
export class UserService extends FirestoreService<User> {
  protected basePath: string = 'users';
  public user: User | any;

  public userModelSubject?: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  //Constructor for UserService, taking Firestore, Router and NgZone as parameters
  constructor(
    fireStore: Firestore,
    public router: Router,
    public ngZone: NgZone,
  ) {
    super(fireStore);  // Calling parent class (FirestoreService) constructor with Firestore as argument

    // if (this.user) {
    //   if (!this.user.companyId) {
    //     debugger;
    //     console.log("User Service : Line 29");
    //     this.router.navigate(['/register/company-profile']);
    //   }
    //   // if (!this.user.planId) {
    //   //   this.router.navigate(['/register/select-plan']);
    //   // }
    // }

  }

  // Function to get all users of a company based on the provided companyId
  getAllCompanyUsers(companyId: string) {

    // Define a custom query function using Firestore query chaining methods
    const customQueryFn = (collectionRef: any) => {
      return query(collectionRef, where('companyUid', '==', companyId));
    };

    return this.getCollection$(customQueryFn);
  }

  /**
   * Fetches the company associated with the given user ID from Firestore and returns it as an Observable.
   * This method subscribes to Firestore and continuously listens for changes, emitting only when a valid company
   * is found for the specified user. It ensures at least one company object is returned, not resolving until such
   * a company object is available.
   *
   * @param userId The user ID to fetch the associated company for.
   * @returns An Observable that emits the company object associated with the user ID.
   */
  getCompanyByUserId(userId: string): Observable<Company> {
    return new Observable(observer => {
      const companyCollection = collection(this.fireStore, 'company');
      const q = query(companyCollection, where('ownerId', '==', userId));

      const unsubscribe = onSnapshot(q, (querySnapshot: QuerySnapshot) => {
        const companies: any[] = [];
        querySnapshot.forEach((doc: QueryDocumentSnapshot) => {
          const data = doc.data();
          const id = doc.id;
          companies.push({ id, ...data });
        });

        if (companies.length > 0) {
          observer.next(companies[0]); // Emitting only the first company found
        }
      }, (err: any) => {
        observer.error(err);
      });

      // This will ensure that the Firestore listener is detached when this observable is no longer used.
      return () => unsubscribe();
    }).pipe(
      filter((company: any) => company != null) // Ensuring only non-null company objects are emitted
    );
  }
  // Function to get the current user model as an observable
  getUserModel(): Observable<User | null> {
    return this.userModelSubject ? this.userModelSubject.asObservable() : new Observable();
  }

  // Function to set a new user model
  setUserModel(user: User) {
    if (user) {
      // TODO This isnt working debugger;
      if (this.userModelSubject) {
        this.userModelSubject.next(user);
      }
      if (!user.companyId) {
        this.router.navigate(['/register/company-profile']);
      }
      // if (!user.planId) {
      //   this.router.navigate(['/register/select-plan']);
      // }
    }
  }


  // Function to get or create a user model
  async getOrCreateUserModel(user: User): Promise<User> {
    // Attempting to fetch user model from Firestore based on UID
    let userModel: User | any = await this.getDocument(user.uid);
    this.setUserSession(userModel);

    // Check if user model exists, if it does verify its configuration
    if (userModel && userModel.uid) {
      this.checkUserIsConfigured(userModel);
    } else {
      // If user model does not exist, create a new one
      this.create(user, user.uid);
      userModel = await this.getDocument(user.uid);
    }
    userModel = new User(userModel);
    // If a user's displayName exists and both firstName and lastName are not set, split the displayName
    if (user.displayName && !userModel.firstname && !userModel.lastName) {
      const names = user.displayName.split(' ');

      userModel.firstName = names[0];
      userModel.lastName = names[1];
    }
    this.setUserModel(userModel);
    return userModel;
  }
  // Function to check if a user is already configured
  checkUserIsConfigured(user: any) {
    // If user is already set, return immediately

    if (this.user) return;
    if (user && user.uid) {
      this.getDocument(user.uid)
        .then(async (firebaseUser) => {
          if (firebaseUser) {
            // Convert the fetched user data to User model
            this.user = new User(firebaseUser);
            if (this.user) {
              if (!this.user.companyId) {
                return this.router.navigate(['/register/company-profile']);
              }
              // if (!this.user.planId) {
              //   return this.router.navigate(['/register/select-plan']);
              // }
              return;
            }
            return;
          } else {
            return false;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      return;
    }
  }


  setUserSession(userModel: User) {
    sessionStorage.setItem('user', JSON.stringify(userModel));
  }

  /**
   * Overide this on each class
   * @returns FirestoreDataConverter<any>
   */
  // Override the getConverter method to provide custom toFirestore and fromFirestore methods
  override getConverter(): FirestoreDataConverter<any> {
    return {
      // Convert User object to Firestore-compatible format
      toFirestore: (user: any) => {
        let userData = {
          uid: user.uid,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          lastLoginAt: user.lastLoginAt,
          createdAt: user.createdAt ? user.createdAt : new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          displayName: user.displayName,
          emailVerified: user.emailVerified,
          isAnonymous: user.isAnonymous,
          metadata: user.metadata,
          phoneNumber: user.phoneNumber,
          photoURL: user.photoURL,
          providerData: user.providerData,
          providerId: user.providerId,
          reloadListener: user.reloadListener,
          reloadUserInfo: user.reloadUserInfo,
          tenantId: user.tenantId,
          jobTitle: user.jobTitle,
          phone: user.phone,
          about: user.about,
          companyId: user.companyId,
          companyLocation: user.companyLocation,
          planId: user.planId,
          socialLinks: user.socialLinks,
        };
        userData = this.removeEmpty(userData); // Remove any empty/null values
        return userData;
      },
      // Convert Firestore snapshot to User object
      fromFirestore: (snapshot: any, options: any) => {
        const data = snapshot.data(options);
        return new User(data);
      },
    };
  }
}
