
import { FileUpload } from "./file-upload";

export class Company {
  uid!: string;
  accountUuid!: string
  name!: string;
  domain!: string;
  description!: string;
  image_url!: string;
  createdAt!: string;
  updatedAt!: string;
  fileUpload?: FileUpload;
  headquarters?: string;
  locations?: string[];
  ownerId!: string;
  constructor(data: object) {
    Object.assign(this, data);
  }
}

