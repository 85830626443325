<div class="modal-header">
  <h3 class="modal-title">Alert</h3>
  <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="forceCloseModal()" (keyup)="forceCloseModal()" tabindex="0">
    <span class="visually-hidden">Close</span>
  </button>
</div>
<div class="modal-body">
  <p>{{ message }}</p>
</div>
<div class="modal-footer">
  <button *ngIf="showYesNoButtons" type="button" class="btn btn-secondary" (click)="closeModal('no')" (keyup)="closeModal('no')" tabindex="1">No</button>
  <button *ngIf="showYesNoButtons" type="button" class="btn btn-primary" (click)="closeModal('yes')" (keyup)="closeModal('yes')" tabindex="2">Yes</button>
  <button *ngIf="!showYesNoButtons" type="button" class="btn btn-info" (click)="closeModal('close')" (keyup)="closeModal('close')" tabindex="1">Close</button>
</div>
