import { Injectable } from '@angular/core';
import { FirestoreService } from '../../services/firestore.abstract.service';
import { DocumentData, Firestore } from '@angular/fire/firestore';
import { TenableScan } from '../models/tenable-scan';
import { Company } from '@core/models/company';
import { CompanyService } from '@core/services/company.service';

@Injectable({
  providedIn: 'root'
})
export class TenableScansFireStoreService extends FirestoreService<TenableScan> {

  protected override basePath: string = "tenable_scan";
  private company?: Company;

  constructor(fireStore: Firestore, private companySevice: CompanyService
  ) {
    super(fireStore);
    if(this.companySevice.company) {
      this.company = this.companySevice.company;
    }
    this.companySevice.getCurrentUserCompany().subscribe((company: Company) => {
      this.company = company;
    });
  }


  async getScans(): Promise<any[]> {
    if(this.company) {
      const query = await this.getCollection$(null,this.company.uid);
      if (query.empty) {
        return [];
      } else {
        const dataObjects: DocumentData[] = [];
        query.forEach((doc: any) => {
          const obj = doc.data();
          dataObjects.push(obj);
        });
        return dataObjects;
      }
    } else {
      return [];
    }
  }

  async updateScans(scans: TenableScan[]): Promise<void> {
    scans.forEach((scan) => {
      if(this.company){
        scan.companyUid = this.company.uid;
        this.update(scan, scan.schedule_uuid).then(() => { });
      }
    });
  }

  getScan(uid: string) {
    return this.getDocument(uid);
  }

  updateScan(scan: any) {
    return this.update(scan, scan.schedule_uuid);
  }
}
