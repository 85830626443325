import { inject } from '@angular/core';
import { CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { UserService } from '@core/services/user.service';
import { AuthService } from '@core/services/auth.service';
import { User } from '@core/models/user';
import { CompanyService } from '@core/services/company.service';

export const adminAuthGuard: CanActivateFn = async (_route, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const userService = inject(UserService);
  const companyService = inject(CompanyService);
  const router = inject(Router);

  const storedAccountDetails = sessionStorage.getItem('accountDetails');
  const user = await authService.getUserSession();

  sessionStorage.setItem('redirectUrl', state.url);

  if (!user || !(user instanceof User) || !user.email) {
    return handleUnauthenticatedUser(router, state.url);
  }

  userService.setUserModel(user); // Refresh user model
  authService.setUserSession(user); // Refresh user session

  const emailDomain = user.email.split('@')[1].toLowerCase();
  const blockedDomains = ['gmail.com', 'outlook.com', 'yahoo.com', 'hotmail.com'];

  if (blockedDomains.includes(emailDomain)) {
    console.error('Login using public email providers is not allowed.');
    await authService.signOutUser();
    return router.createUrlTree(['/login']);
  }

  companyService.user = user;
  companyService.setUserProfile();

  // if (!companyService.checkIfUserHasCompany()) {
  //   console.log('User does not have a company profile -- Admin Auth Guard 41');
  //   return router.createUrlTree(['/register/company-profile']);
  // }

  if (storedAccountDetails) {
    return true; // Account details are present, no need to check for expiration
  }

  const accountIsExpired: boolean = await authService.isAccountExpired(user);

  return handleAuthenticatedUser(router, accountIsExpired);
};

const handleUnauthenticatedUser = async (router: Router, redirectUrl: string): Promise<boolean | UrlTree> => {
  const sessionUser = sessionStorage.getItem('user');

  if (sessionUser) {
    return true; // User is logged in, so allow access
  }

  sessionStorage.setItem('redirectUrl', redirectUrl);
  return router.createUrlTree(['/login'], { queryParams: { returnUrl: redirectUrl } });
};

const handleAuthenticatedUser = async (router: Router, accountIsExpired: boolean): Promise<boolean | UrlTree> => {
  if (accountIsExpired) {
    return router.createUrlTree(['/login']);
  }

  return true;
};
