<section class="vertical-navigation left bg-body" [class.active]="sideBarvisible">
  <!-- TODO Remove this section I think  -->
  <div class="company-profile" *ngIf="companyModel">
    <div class="company-img">
      <a [routerLink]="'/dashboard/company'" [title]="companyModel.name">
        <div class="img">
          <img *ngIf="companyModel.image_url" [src]="companyModel.image_url" [alt]="companyModel.name">
          <img *ngIf="!companyModel.image_url" src="assets/images/buildings.jpg" [alt]="companyModel.name">
        </div>
      </a>
    </div>
    <div class="company-info">
      <p class="text-info name" *ngIf="companyModel.name" [innerText]="companyModel.name"></p>
    </div>

  </div>
  <ul class="sidebar-nav nav nav-pills d-flex flex-column" (click)="toggleSidebar()" (keyup)="toggleSidebar()">

    <li class="nav-item dashboard waves-effect waves-teal p-2" [routerLink]="'/dashboard'" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" tabindex="0">
      <div class="d-flex flex-row">
        <div class="img-nav">
          <i class="fa fa-gauge" aria-hidden="true"></i>
        </div>
        <span class="nav-title">Dashboard</span>
      </div>
    </li>

    <li *ngFor="let navitem of sidebarLinks" [class.active]="activeMenu === navitem.title" class="{{navitem.title}} waves-effect waves-teal p-2 nav-item" [routerLink]="navitem.route" routerLinkActive="active">
      <div class="d-flex flex-row" (click)="toggleActiveMenu(navitem.title)" (keyup)="toggleActiveMenu(navitem.title)" tabindex="0">
        <div class="img-nav">
          <i class="fa {{navitem.iconImg}}" aria-hidden="true"></i>
        </div>
        <span class="nav-title">{{ navitem.title }}
          <ng-container *ngIf="isObservable(navitem.count)">
            <ng-container *ngIf="navitem.count | async as count">
              <span *ngIf="count !== null" class="nav-count" [innerHTML]="count"></span>
            </ng-container>
          </ng-container>
        </span>
      </div>
      <!-- Submenu -->
      <ul *ngIf="navitem.sub_links && activeMenu === navitem.title" class="nav nav-pills d-flex flex-column text-left">
        <li *ngFor="let sublink of navitem.sub_links" class="{{navitem.title}} waves-effect waves-teal ps-3 p-2 nav-item">
          <div class="d-flex flex-row">
            <a [routerLink]="sublink.route" [routerLinkActive]="'active'" [title]="sublink.title" [routerLinkActiveOptions]="{ exact: true }" class="d-flex flex-row">
              <div class="img-nav">
                <i class="fa {{sublink.iconImg}}" aria-hidden="true"></i>
              </div>
              <span class="nav-title">{{ sublink.title }}
                <ng-container *ngIf="isObservable(sublink.count)">
                  <ng-container *ngIf="sublink.count | async as count">
                    <span *ngIf="count !== null" class="nav-count" [innerHTML]="count"></span>
                  </ng-container>
                </ng-container>
              </span>
            </a>
          </div>
        </li>
      </ul>
    </li>
  </ul>

</section><!-- /.vertical-navigation -->
