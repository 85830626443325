import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { User } from 'src/app//core/models/user';
import { UserService } from '@core/services/user.service';
import { ThemeService } from '@core/services/theme.service';
import { TenableChildAccount } from '@core/tenable/models/tenable-child-account';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  // showProfileMenu = false;
  showNotyMenu = false;
  sideBarvisible = false;
  userModel: User | null = null;
  mspAccount?: TenableChildAccount | null;

  dark: boolean = true;

  @ViewChild('notyButton') notyButton!: ElementRef;
  @ViewChild('notyMenu') notyMenu!: ElementRef;
  @ViewChild('profileBtn') profileBtn!: ElementRef;
  @ViewChild('profileMenu') profileMenu!: ElementRef;

  constructor(
    private themeService: ThemeService,
    private userService: UserService,
    private renderer: Renderer2
  ) {
    // https://stackoverflow.com/questions/51150422/how-to-detect-click-outside-of-an-elem`ent-in-angular
    this.renderer.listen('window', 'click', (e: Event) => {

      // // Profile Links
      // if (!this.profileMenu.nativeElement.contains(e.target)
      // && !this.profileBtn.nativeElement.contains(e.target)
      // ) {
      //   this.showProfileMenu = false;
      // }

      // Notification Buttons
      if(!this.notyButton.nativeElement.contains(e.target) && !this.notyMenu.nativeElement.contains(e.target)) {
        this.showNotyMenu = false;

      }
    });
    this.themeService.getSidebarIsVisable().subscribe((value: boolean) => {
      this.sideBarvisible = value;
    });
  }

  ngOnInit(): void {
    this.userService.getUserModel().subscribe((user) => {
      if (user && user.uid) {
        this.userModel = user;
      }
    });
    this.themeService.getAccountModel().subscribe(mspAccount=>{
      this.mspAccount = mspAccount;
    });
    this.getAccountDetails();
  }

  showProfileMenu: boolean = false;

  toggleMenu() {
    this.showProfileMenu = !this.showProfileMenu;
    if (this.showProfileMenu) {
      this.showMenu();
    } else {
      this.hideMenu();
    }
  }

  showMenu() {
    this.showProfileMenu = true;
    // this.showProfileMenu = !this.showProfileMenu;
  }

  hideMenu() {
    this.showProfileMenu = false;
  }

  showNotifications() {
    this.showNotyMenu = !this.showNotyMenu;
  }

  toggleSidebar() {
    this.themeService.toggleSidebarVisibility();
  }

  getAccountDetails() {
    const mspAccountData = sessionStorage.getItem('accountDetails');
    if(mspAccountData) {
      this.mspAccount = new TenableChildAccount(JSON.parse(mspAccountData));
    }

  }
  toggleDarkMode() {
    this.dark = !this.dark;
    document.body.setAttribute('data-bs-theme', this.dark ? 'dark' : 'light');
    sessionStorage.setItem('darkMode', this.dark ? 'dark' : 'light');
  }
}
