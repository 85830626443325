import { SocialLinks } from "./social-links";

export class User {
  email!: string;
  firstName!: string;
  lastName!: string;
  lastLoginAt!: string;
  createdAt!: string;
  updatedAt!: string;
  displayName!: string;
  emailVerified!: string;
  isAnonymous!: string;
  metadata!: {
    createdAt: string,
    lastLoginAt: string
  };
  phoneNumber!: string;
  photoURL!: string;
  providerData!: string;
  providerId!: string;
  reloadListener!: string;
  reloadUserInfo!: string;
  tenantId!: string;
  uid!: string;
  primaryProvider!: string;

  role?: string;
  phone?: string;
  jobTitle?: string;
  about?: string;
  companyId?: string;

  companyLocation?: string;

  socialLinks!: SocialLinks

  planId!: string;

  constructor(data: object) {
    Object.assign(this, data);
  }
}

