import { ThemeService } from '@core/services/theme.service';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AlertModalComponent } from '../shared/alert-modal/alert-modal.component';
import { MsspService } from '@core/tenable/service/mssp.service';
import { UserService } from '@core/services/user.service';
import { User } from '@core/models/user';
import { Company } from '@core/models/company';
import { CompanyService } from '@core/services/company.service';
import { AuthService } from '@core/services/auth.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  public showSideBar: boolean = true;
  public spinnerShow: boolean = true;

  @ViewChild('alertModal') alertModal!: AlertModalComponent;

  userModel!: User;
  companyModel!: Company;


  constructor(
    private msspService: MsspService,
    private themeService: ThemeService,
    private userSvc: UserService,
    private companySvc: CompanyService,
    private authService: AuthService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.getUserCompany();
  }

  ngOnInit(): void {
    this.themeService.getShowSpinner().subscribe(showSpinner => {
      this.spinnerShow = showSpinner;
      this.cdRef.detectChanges(); // Manually trigger change detection
    });

    this.themeService.currentErrorMessage.subscribe(message => {

      if (message) {
        const modalRef = this.modalService.open(AlertModalComponent);
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.showYesNoButtons = false;
      }
    });
  }

  async getUserCompany() {
    // this.themeService.showSpinner();
    await this.userSvc.getUserModel().subscribe(user => {
      if (user && user.uid) {
        // this.themeService.hideSpinner();
        this.userModel = user;

        this.userSvc.getCompanyByUserId(this.userModel.uid).subscribe((company: Company) => {
          if (company && company.uid) {
            this.companyModel = company;
            if (this.companyModel && !this.companyModel.domain) {
              this.companyModel = this.companySvc.updateDomainFromOwnerEmail(this.companyModel, this.userModel);
            }
            // if (!this.companyModel.accountUuid) {
            this.checkMsspAccountDetails();
            // }
          }
        });


      }
    });
  }

  checkMsspAccountDetails() {
    try {
      // Check if account details are stored in session storage
      const storedAccountDetails = sessionStorage.getItem('accountDetails');
      if (storedAccountDetails) {
        const accountModel = JSON.parse(storedAccountDetails);
        if (accountModel.continaer_name) {
          // Proceed with using the stored account details
          if (accountModel.container_name != this.companyModel.domain) {
            this.themeService.triggerError('An error occurred: No Account Found. Contact Support. Logging out..');
            this.logoutUser();
          }
        } else {
          // If not stored, proceed to fetch and store account details
          this.msspService.listChildAccounts().subscribe((res: any) => {
            if (res && this.companyModel) {
              const accountModel = res.accounts.filter((company: any) => company.container_name === this.companyModel.domain)[0];
              const validAccount = this.msspService.validateAccount(accountModel, this.companyModel);
              if (!validAccount) {
                this.logoutUser();
              }
            }
          });
        }
      }
    } catch (error: any) {
      console.error('An error occurred: ' + error.message);
    }
  }


  // private validateAccount(accountModel: any) {

  //   // this.themeService.hideSpinner();
  //   if (!accountModel) {
  //     if (!environment.production) {
  //       this.msspService.createMsspAccount(this.companyModel, this.userModel);
  //     } else {
  //       this.themeService.triggerError('An error occurred: No Account Found. Contact Support. Logging out..');
  //       this.logoutUser();
  //     }
  //   } else {
  //     const licenseExpirationDate: Date = new Date(accountModel.license_expiration_date * 1000);
  //     const now: Date = new Date();
  //     debugger;
  //     if (accountModel.license_expiration_date === 0) {
  //       debugger;
  //       this.themeService.triggerError('Your account is creating... Log back in later to see if creation is complete.');
  //       this.logoutUser();
  //     } else if (licenseExpirationDate < now) {
  //       debugger;
  //       this.themeService.triggerError('Your account is expired. Logging out..');
  //       const accountError = {
  //         error: "Account is expired.",
  //         accountModel: accountModel,
  //         companyModel: this.companyModel
  //       };
  //       sessionStorage.setItem('accountError', JSON.stringify(accountError));
  //       this.logoutUser();
  //     } else {
  //       debugger;
  //       if (accountModel && this.companyModel.accountUuid == null) {
  //         this.companyModel.accountUuid = accountModel.uuid;
  //         this.companySvc.update(this.companyModel, this.companyModel.uid).then(() => {
  //           // After successful update, store account details in session storage
  //           const mspAccount = new TenableChildAccount(accountModel);
  //           this.themeService.setAccountModel(mspAccount);
  //         });
  //       }
  //       if (accountModel) {
  //         sessionStorage.setItem('accountDetails', JSON.stringify(accountModel));
  //       }
  //     }
  //   }
  // }

  logoutUser() {
    this.authService.signOutUser();
    setTimeout(() => {
      if (this.alertModal) {
        this.alertModal.forceCloseModal();
      }
      return this.router.navigate(['/login']);
    }, 5000)
  }
}
