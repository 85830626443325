import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
  providers: [NgbActiveModal]
})
export class AlertModalComponent {

  @Input() message: string = '';
  @Input() showYesNoButtons: boolean = false;
  @Output() confirmed = new EventEmitter<boolean>();

  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal) { }

  closeModal(result: string) {
    if (result === 'yes') {
      this.confirmed.emit(true);
    }
    this.modalService.dismissAll();
  }

  forceCloseModal() {
    this.modalService.dismissAll();
  }
}
