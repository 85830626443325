import { CompanyService } from 'src/app/core/services/company.service';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { UserService } from './user.service';
import { AuthProvider, UserCredential, getAuth, onAuthStateChanged, signInWithPopup, signOut } from '@angular/fire/auth';
import { User } from '../models/user';
import { MsspService } from '@core/tenable/service/mssp.service';
import { ErrorHandlerService } from 'src/app/error-handler.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {

  isLoggedIn = false;
  userModel?: User | any;
  userData?: User | null; // Save logged in user data

  // store the URL so we can redirect after logging in
  redirectUrl: string | null = null;

  private currentUserSubject!: BehaviorSubject<any>;
  public currentUser!: Observable<any>;
  public accountExpired: boolean | null = null;
  private isGettingScanners = false;
  private company_slug?: string;

  constructor(
    private userService: UserService,
    private companyService: CompanyService,
    private msspService: MsspService,
    private errorHandler: ErrorHandlerService
  ) {
    this.checkAuthChange();
    this.checkIfLoggedIn();
  }

  ngOnDestroy() {
    this.currentUserSubject.unsubscribe();
  }

  /**
   * Checks for changes in Firebase Authentication state.
   * If a user is logged in, it creates or fetches their details.
   * If no user is logged in, it resets user details and authentication state.
   */
  checkAuthChange() {
    onAuthStateChanged(getAuth(), async (user: any) => {
      if (user) {
        const userModel = new User(user);
        const userObject = await this.userService.getOrCreateUserModel(userModel);
        this.companyService.user = userObject;
        this.userData = userObject;
        this.isLoggedIn = true;
      } else {
        this.removeUserSession();
      }
    })
  }

  hasCompany() {
    if (!this.userModel) {
      this.errorHandler.handleError('User model not found');
      console.error('User model not found');
      return false;
    }
    this.companyService.user = this.userModel;
    this.companyService.setUserProfile();
    if (!this.companyService.checkIfUserHasCompany()) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Signs out the user and removes user session from sessionStorage.
   */
  async signOutUser() {
    await signOut(getAuth());
    sessionStorage.removeItem('user');
  }

  /**
   * Runs authentication providers (Google, Microsoft, NOT EMAIL/PASS) for login.
   * @param provider The authentication provider.
   * @returns A promise that resolves to an object with a success property indicating the success of the login.
   */
  async AuthLogin(provider: AuthProvider) {
    return signInWithPopup(getAuth(), provider).then((res: UserCredential) => {
      if (res) {
        if (res.user && res.user.email) {
          const emailDomain = res.user.email.split('@')[1].toLowerCase();
          const blockedDomains = ['gmail.com', 'outlook.com', 'yahoo.com', 'hotmail.com'];
          if (blockedDomains.includes(emailDomain)) {
            console.error('Login using public email providers is not allowed.');
            this.signOutUser();
            return Promise.reject('Login using public email providers is not allowed.');
          }
        }
        if (this.signinUser(res)) {
          return { success: true, userCred: res };
        } else {
          return { success: false, userCred: res };
        }
      } else {
        return { success: false, userCred: res };
      }
    }).catch(error => {
      if (error.code === 'auth/account-exists-with-different-credential') {
        // this.handleAccountExists(error.customData);
      } else {
        console.error(error);
      }
      return { success: false, error: error };
    });
  }

  /**
   * Signs in the user and checks if the user has a company profile and a selected plan.
   * @param userCred The user credential.
   * @returns A boolean indicating whether the user was successfully signed in.
   */
  private signinUser(userCred: UserCredential): boolean {
    const userModel = new User(userCred.user);
    this.userData = userModel;
    this.userService.getOrCreateUserModel(userModel);
    if (userModel) {
      this.isLoggedIn = true;
      return true;
    } else {
      return false;
    }
  }

  /**
   * Checks if the user is logged in and has user data.
   * @returns A boolean indicating whether the user is logged in.
   */
  checkIfLoggedIn() {
    if (this.isLoggedIn && this.userData) {
      return true;
    }
    return false;
  }

  /**
   * Sets the user session in sessionStorage.
   * @param userModel The user model to be stored in the session.
   */
  setUserSession(userModel: User) {
    sessionStorage.setItem('user', JSON.stringify(userModel));
  }

  /**
   * Retrieves the user session from sessionStorage.
   * @returns The user model if it exists, otherwise false.
   */
  async getUserSession() {
    const currentUser = sessionStorage.getItem('user');
    if (currentUser) {
      const userObject = JSON.parse(currentUser)
      const userModel = await this.userService.getDocument(userObject.uid);
      if (userModel) {
        this.userModel = new User(userModel);
        this.userService.user = this.userModel;
        this.companyService.user = this.userModel;
        this.currentUserSubject = new BehaviorSubject<any>(userModel);
        this.currentUser = this.currentUserSubject.asObservable();
        this.isLoggedIn = true;
        return this.userModel;
      } else {
        this.isLoggedIn = false;
        return this.isLoggedIn;
      }
    } else {
      this.currentUserSubject = new BehaviorSubject<any>(null);
      this.isLoggedIn = false;
      return this.isLoggedIn;
    }
  }

  /**
   * Removes the user session from sessionStorage.
   */
  removeUserSession() {
    this.isLoggedIn = false;
    this.userData = null;
    sessionStorage.removeItem('user');
  }

  /**
   * Checks if the account is expired via the Tenable service scanner list call.
   * If the account is expired, sets an error message in session storage and returns false.
   * Otherwise, returns true.
   *
   * @returns {Promise<boolean>} Promise resolving to true if the account is not expired, false otherwise.
   */
  async isAccountExpired(user: User): Promise<boolean> {
    // If the account expiration status is already determined, return it
    // Retrieve the accountExpired status from sessionStorage if it exists
    const storedAccountExpired = sessionStorage.getItem('accountExpired');
    if (storedAccountExpired !== null) {
      this.accountExpired = storedAccountExpired === 'true';
      return this.accountExpired;
    }

    if (this.accountExpired !== null) {
      return this.accountExpired;
    }
    // Prevent multiple calls by returning the current status if a check is already in progress
    if (this.isGettingScanners) {
      if (!environment.production && environment.debug)
        console.log("Another call to isAccountExpired is already in progress. Waiting for the result...");
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (!this.isGettingScanners) {
            clearInterval(interval);
            resolve(this.accountExpired ?? false);
          }
        }, 100); // Check every 100ms
      });
    }

    try {
      // Set the flag to prevent multiple calls to the Tenable service
      this.isGettingScanners = true;

      // Set user model and profile
      this.userService.setUserModel(user);
      this.userModel = user;
      this.companyService.setUserProfile();

      const companyModel = await firstValueFrom(this.userService.getCompanyByUserId(this.userModel.uid));
      if (!companyModel) {
        this.accountExpired = true;
        sessionStorage.setItem('accountExpired', 'true');
        this.isGettingScanners = false;
        return this.accountExpired;
      } else {
        this.companyService.company = companyModel;
      }

      // Wait for the company slug to be set
      await this.companyService.getCompanySlug().subscribe(slug => {
        if (slug) {
          this.company_slug = slug;
          this.msspService.companySlug = this.company_slug;
        }
      });

      // const accountDetails = sessionStorage.getItem('accountDetails');
      const accountDetails = JSON.parse(sessionStorage.getItem('accountDetails') || '{}');
      if (accountDetails) {
        const validAccount = this.msspService.validateAccount(accountDetails, companyModel);
        if (!validAccount) {
          const accountError = {
            message: "Your account has expired. Please contact support for assistance."
          };
          sessionStorage.setItem('accountError', JSON.stringify(accountError));
          this.accountExpired = true;
        } else {
          this.accountExpired = false;
        }

      } else {
        const accountRes = await firstValueFrom(this.msspService.listChildAccounts());

        if (!accountRes || accountRes.accounts.length === 0) {
          const createdAccount = await this.msspService.createMsspAccount(companyModel, this.userModel);
          this.accountExpired = !createdAccount;
        } else {
          if (companyModel) {
            const accountModel = accountRes.accounts.find((company: any) => company.container_name === companyModel.domain);
            if (!accountModel) {
              // If the account does not exist, create it
              const accountCreated = await this.msspService.createMsspAccount(companyModel, this.userModel);
              this.accountExpired = !accountCreated;
            } else {
              const validAccount = this.msspService.validateAccount(accountModel, companyModel);
              if (!validAccount) {
                const accountError = {
                  message: "Your account has expired. Please contact support for assistance."
                };
                sessionStorage.setItem('accountError', JSON.stringify(accountError));
                this.accountExpired = true;
              } else {
                this.accountExpired = false;
              }
            }
          } else {
            this.accountExpired = true;
          }
        }
      }

      // Store the accountExpired status in sessionStorage
      sessionStorage.setItem('accountExpired', this.accountExpired ? 'true' : 'false');
      this.isGettingScanners = false;
      return this.accountExpired;
    } catch (error: any) {
      // Handle errors and assume the account is not expired
      console.error("An unexpected error occurred:", error);
      this.errorHandler.handleError(error);
      this.accountExpired = false;
      sessionStorage.setItem('accountExpired', 'false');
      this.isGettingScanners = false;
      return this.accountExpired;
    }
  }



  logoutUser() {
    this.signOutUser();
  }

}
